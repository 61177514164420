import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import GoDoc from "../views/GoDoc.vue";
import StepZero from "../views/dm/StepZero.vue";
import StepOne from "../views/dm/spb/info/StepOne.vue";
import StepOneOne from "../views/dm/spb/info/StepOneOne.vue";
import StepTwo from "../views/dm/spb/info/StepTwo.vue";
import StepTwoOne from "../views/dm/spb/info/StepTwoOne.vue";
import StepTwoTwo from "../views/dm/spb/info/StepTwoTwo.vue";
import StepThree from "../views/dm/spb/info/StepThree.vue";
import StepThreeOne from "../views/dm/spb/info/StepThreeOne.vue";
import StepThreeTwo from "../views/dm/spb/info/StepThreeTwo.vue";
import StepThreeThree from "../views/dm/spb/info/StepThreeThree.vue";
import StepThreeFour from "../views/dm/spb/info/StepThreeFour.vue";
import StepThreeFive from "../views/dm/spb/info/StepThreeFive.vue";
import StepThreeSix from "../views/dm/spb/info/StepThreeSix.vue";
import StepThreeSeven from "../views/dm/spb/info/StepThreeSeven.vue";
import StepFour from "../views/dm/spb/info/StepFour.vue";
import StepFourOne from "../views/dm/spb/info/StepFourOne.vue";
import StepFourTwo from "../views/dm/spb/info/StepFourTwo.vue";
import StepFive from "../views/dm/spb/info/StepFive.vue";
import StepSix from "../views/dm/spb/info/StepSix.vue";
import StepSeven from "../views/dm/spb/info/StepSeven.vue";
import StepEight from "@/views/dm/spb/info/StepEight.vue";
import StepNine from "@/views/dm/spb/info/StepNine.vue";
import StepTen from "@/views/dm/spb/info/StepTen.vue";
import StepEleven from "@/views/dm/spb/info/StepEleven.vue";
import StepTwelve from "@/views/dm/spb/info/StepTwelve.vue";
import StepThirteen from "@/views/dm/spb/info/StepThirteen.vue";
import Continue from "@/views/Continue.vue";
import SignUp from "@/views/SignUp.vue";
import DocList from "@/views/DocList.vue";
import RecoveryPwd from "@/views/RecoveryPwd.vue";
import Recovery from "@/views/Recovery.vue";
import SettingsUser from "@/views/SettingsUser.vue";
import ProxyStep1 from "@/views/proxy/ProxyStep1.vue";
import ProxyStep2 from "@/views/proxy/ProxyStep2.vue";
import ProxyStep3 from "@/views/proxy/ProxyStep3.vue";
import ProxyStep4 from "@/views/proxy/ProxyStep4.vue";
import ProxyStep5 from "@/views/proxy/ProxyStep5.vue";
import ProxyStep6 from "@/views/proxy/ProxyStep6.vue";
import ProxyStep7 from "@/views/proxy/ProxyStep7.vue";
import ProxyStep8 from "@/views/proxy/ProxyStep8.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/go",
    name: "go",
    component: GoDoc,
  },
  {
    path: "/SignUp",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/recovery",
    name: "recovery",
    component: Recovery,
  },
  {
    path: "/list",
    name: "list",
    component: DocList,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsUser,
  },
  {
    path: "/step0/:docID",
    name: "step0",
    component: StepZero,
  },
  {
    path: "/step1/:docID",
    name: "step1",
    component: StepOne,
  },
  {
    path: "/step1_1/:docID",
    name: "step1_1",
    component: StepOneOne,
  },
  {
    path: "/step2/:docID",
    name: "step2",
    component: StepTwo,
  },
  {
    path: "/step2_1/:docID",
    name: "step2_1",
    component: StepTwoOne,
  },
  {
    path: "/step2_2/:docID",
    name: "step2_2",
    component: StepTwoTwo,
  },
  {
    path: "/step3/:docID",
    name: "step3",
    component: StepThree,
  },
  {
    path: "/step3_1/:docID",
    name: "step3_1",
    component: StepThreeOne,
  },
  {
    path: "/step3_2/:docID",
    name: "step3_2",
    component: StepThreeTwo,
  },
  {
    path: "/step3_3/:docID",
    name: "step3_3",
    component: StepThreeThree,
  },
  {
    path: "/step3_4/:docID",
    name: "step3_4",
    component: StepThreeFour,
  },
  {
    path: "/step3_5/:docID",
    name: "step3_5",
    component: StepThreeFive,
  },
  {
    path: "/step3_6/:docID",
    name: "step3_6",
    component: StepThreeSix,
  },
  {
    path: "/step3_7/:docID",
    name: "step3_7",
    component: StepThreeSeven,
  },
  {
    path: "/step4/:docID",
    name: "step4",
    component: StepFour,
  },
  {
    path: "/step4_1/:docID",
    name: "step4_1",
    component: StepFourOne,
  },
  {
    path: "/step4_2/:docID",
    name: "step4_2",
    component: StepFourTwo,
  },
  {
    path: "/step5/:docID",
    name: "step5",
    component: StepFive,
  },
  {
    path: "/step6/:docID",
    name: "step6",
    component: StepSix,
  },
  {
    path: "/step7/:docID",
    name: "step7",
    component: StepSeven,
  },
  {
    path: "/step8/:docID",
    name: "step8",
    component: StepEight,
  },
  {
    path: "/step9/:docID",
    name: "step9",
    component: StepNine,
  },
  {
    path: "/step10/:docID",
    name: "step10",
    component: StepTen,
  },
  /*{
    path: "/step11/:docID",
    name: "step11",
    component: StepEleven,
  },
  {
    path: "/step12/:docID",
    name: "step12",
    component: StepTwelve,
  },*/
  {
    path: "/step11/:docID",
    name: "step11",
    component: StepThirteen,
  },
  {
    path: "/continue/:docID",
    name: "continue",
    component: Continue,
  },
  {
    path: "/proxy/step1/:docID",
    name: "proxy/step1",
    component: ProxyStep1,
  },
  {
    path: "/proxy/step2/:docID",
    name: "proxy/step2",
    component: ProxyStep2,
  },
  {
    path: "/proxy/step3/:docID",
    name: "proxy/step3",
    component: ProxyStep3,
  },
  {
    path: "/proxy/step4/:docID",
    name: "proxy/step4",
    component: ProxyStep4,
  },
  {
    path: "/proxy/step5/:docID",
    name: "proxy/step5",
    component: ProxyStep5,
  },
  {
    path: "/proxy/step6/:docID",
    name: "proxy/step6",
    component: ProxyStep6,
  },
  {
    path: "/proxy/step7/:docID",
    name: "proxy/step7",
    component: ProxyStep7,
  },
  {
    path: "/proxy/step8/:docID",
    name: "proxy/step8",
    component: ProxyStep8,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
